.has-image-centered {
    margin-left: auto;
    margin-right: auto;
  }
  .customImage{
    text-align: center;
  }
  .validationText {
    color:red
  }
  .messageSuccess {
    color: lightgreen;
    margin-top: -30px;
  }